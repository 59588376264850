import * as z from 'zod'
import { zodIs } from './zod-utils'

export const getObjectNestedValue = <T>(obj: unknown, key: string, validator: z.Schema<T>): T | undefined => {
  if (typeof obj === 'object' && obj && !Array.isArray(obj)) {
    const keys = key.split('.')

    const result = keys.reduce((acc, k) => {
      if (acc && k in acc) {
        return acc[k as keyof typeof obj]
      }

      return undefined
    // eslint-disable-next-line @typescript-eslint/ban-types
    }, obj as object | undefined)

    if (zodIs(result, validator)) {
      return result
    }
  }

  return undefined
}

export const arrayPathToString = (path: ReadonlyArray<PropertyKey>): string => path.join('.')
