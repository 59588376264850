import styled from 'styled-components'

export const MainLayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;

  main {
    flex: 1;
  }
`
