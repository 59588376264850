import { QueryClient } from 'react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 50 * 1000, // re-fetch every 50 seconds, get rid of it after using web-socket
      refetchOnWindowFocus: false
    }
  }
})
