import * as z from 'zod'
import { isAxiosError } from '../../utils/axios-utils'

export const ErrorType = z.enum(['NetworkError', 'HttpError', 'ApiError'])
export type ErrorType = z.infer<typeof ErrorType>

type BaseError = {
  readonly type: ErrorType
}

export const ApiErrorCode = z.enum([
  'UnsupportedMediaType',
  'HttpObjectError',
  'JsonObjectError',
  'SessionNotFound',
  'SessionInvalidOrExpired',
  'OAuthProviderError',
  'NoMorePaymentAttemptsLeft',
  'TooManyResults',
  'TransactionFailure',
  'DuplicateFound',
  'CurrencyIsNotValid',
  'MalformedConfig',
  'ExternalServiceError',
  'InternalError',
  'BCryptError',
  'UserNotFound',
  'IncorrectPassword',
  'PasswordIsNotStrongEnough',
  'InvalidConfirmationCode',
  'UserAlreadyExists',
  'InvalidEmail',
  'InvalidPhoneNumber',
  'CompanyNotFound',
  'ConfirmationCodeNotFound',
  'NotPermitted',
  'PlantNotFound',
  'PlantAddressNotUnique',
  'DryerNotFound',
  'InvalidOperatorLoginName',
  'OperatorProfileNotFound',
  'OperatorProfileLoginShouldBeUnique',
  'PlcHttpClientError',
  'AlarmRecommendationNotFound',
  'PlcDataPullNotFound',
  'InvalidUUIDFormat'
])

const ApiErrorObject = z.object({
  debugMessage: z.string().nullable(),
  extCode: ApiErrorCode,
  message: z.string().nullable()
})
type ApiErrorObject = z.infer<typeof ApiErrorObject>

type ApiError = BaseError &
  ApiErrorObject & {
    readonly type: 'ApiError'
  }
const apiError: (e: ApiErrorObject) => ApiError = (error) => {
  return {
    type: 'ApiError',
    ...error
  }
}

type HttpError = BaseError & {
  readonly type: 'HttpError'
  readonly code?: string
  readonly message?: string
}
const httpError: (code?: string, text?: string) => HttpError = (code, text) => {
  return {
    type: 'HttpError',
    code,
    message: text
  }
}

type NetworkError = BaseError & {
  readonly type: 'NetworkError'
  readonly error: unknown
}
const networkError: (error: unknown) => NetworkError = (error) => {
  return { type: 'NetworkError', error }
}

export type GeneralError = ApiError | HttpError | NetworkError

export const toGeneralError = (e: unknown): GeneralError => {
  if (isAxiosError(e)) {
    if (e.response?.data) {
      const errorObj = ApiErrorObject.safeParse(e.response.data)
      if (errorObj.success) {
        return apiError(errorObj.data)
      } else {
        return httpError(e.code, e.message)
      }
    } else {
      return httpError(e.code, e.message)
    }
  } else {
    return networkError(e)
  }
}
