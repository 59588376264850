import * as z from 'zod'
import { QueryKey } from 'react-query'

export const CompanyId = z.string().nonempty().uuid()
export type CompanyId = z.infer<typeof CompanyId>

export const UserId = z.string().nonempty().uuid()
export type UserId = z.infer<typeof UserId>

export const DryerId = z.string().nonempty().uuid()
export type DryerId = z.infer<typeof DryerId>

export const PlcId = z.string().nonempty().uuid()
export type PlcId = z.infer<typeof PlcId>

export const FilterOperator = z.enum(['equals', 'contains', 'startsWith', 'endsWith'])

export const Filter = z.object({
  operator: FilterOperator,
  term: z.string().nonempty()
})
export type Filter = z.infer<typeof Filter>

export const Page = z.object({
  nr: z.number().positive(),
  size: z.number().positive()
})
export type Page = z.infer<typeof Page>

export const pageKey: (p: Page) => QueryKey = (p) => [p.nr, p.size]
export const filterKey: (f: Filter) => QueryKey = (f) => [f.operator, f.term]
