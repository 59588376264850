import { IconButton, Toolbar, Typography } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import * as React from 'react'

type Props = {
  readonly title: string
  readonly reload: () => void
}

const TableToolbar: React.FC<Props> = (props) => {
  return (
    <Toolbar>
      <Typography variant="h5" color="textPrimary" gutterBottom>
        {props.title}
      </Typography>
      <IconButton aria-label="reload" onClick={props.reload}>
        <RefreshIcon />
      </IconButton>
    </Toolbar>
  )
}
export default TableToolbar
