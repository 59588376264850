import * as React from 'react'
import { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { ButtonStyled } from './style'
import { SpacingProps } from '@material-ui/system'
import { HtmlMouseButtonEventHandler } from '../../../types/html-types'
import { LocationDescriptor } from 'history'
import { hasValue } from '@digital-magic/ts-common-utils/lib/type'

// There is any combination for props 'to', 'href', 'onClick' is possible (even no any is set), no need to restrict.
export type ButtonProps = MuiButtonProps &
  SpacingProps & {
    readonly to?: LocationDescriptor
  }

// TODO: "to" property may be implemented using component="Link" (from "react-router-dom" library), but it looks like there is a but that prevents it. Try in future versions.
const Button: React.FC<ButtonProps> = ({ children, to, onClick, ...props }) => {
  const history = useHistory()

  const onClickHandler: HtmlMouseButtonEventHandler = (e) => {
    if (hasValue(to)) {
      history.push(to)
    }
    if (hasValue(onClick)) {
      onClick(e)
    }
  }

  return (
    <ButtonStyled {...props} onClick={onClickHandler}>
      {children}
    </ButtonStyled>
  )
}

export default Button
