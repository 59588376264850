import { createGlobalStyle, css } from 'styled-components'
import { arrayIntRange } from '@digital-magic/ts-common-utils/lib/type'

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    html {
      font-size: ${theme.typography.fontSize};
    }

    body {
      font-family: ${theme.typography.fontFamily};
      background-color: ${theme.palette.background.default};
    }

    ${arrayIntRange(1, 10)
      .map(
        (i) => `
          .my-${i} {
            margin-top: ${theme.spacing(i)}px;
            margin-bottom: ${theme.spacing(i)}px;
          }
        `
      )
      .join('')}
  `}
`
