import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormTextField, useForm } from '../../controls/Form'
import { Company, UpdateCompanyRequest } from '../../../api/companies/types'
import Button from '../../controls/Button'
//import { Handler } from '@digital-magic/ts-common-utils/lib/type'

type Props = {
  readonly company?: Company
  // TODO: Why commented type is not accepted by type system?!
  readonly onUpdateSuccess: (data: UpdateCompanyRequest) => void
  //readonly onUpdateSuccess: Handler<UpdateCompanyRequest>
}

const CompanyForm: React.FC<Props> = ({ company, onUpdateSuccess }) => {
  const { t } = useTranslation()

  console.log(company)

  const f = useForm({
    resolver: UpdateCompanyRequest,
    defaultValues: {
      name: company?.name,
      registrationNumber: company?.registrationNumber,
      phoneNumber: company?.phoneNumber,
      email: company?.mail
    }
  })

  return (
    <React.Fragment>
      <h1>{t('forms.company.title')}</h1>
      <Form f={f} onSubmit={onUpdateSuccess}>
        <FormTextField name={f.names.name} label={t('forms.company.fields.name')} />
        <FormTextField name={f.names.registrationNumber} label={t('forms.company.fields.registrationNumber')} />
        <FormTextField name={f.names.email} label={t('forms.company.fields.email')} />
        <FormTextField name={f.names.phoneNumber} label={t('forms.company.fields.phone')} />
        <Button fullWidth type="submit" variant="contained" size="large" color="primary">
          {t('global.buttons.submit')}
        </Button>
      </Form>
    </React.Fragment>
  )
}

export default CompanyForm
