import { MutationFunction, useMutation as useReactMutation, useQueryClient } from 'react-query'
import { GeneralError, toGeneralError } from './errors'
import { useApiErrorHandler } from './useApiErrorHandler'
import { UseMutationOptions, UseMutationResult } from './types'

export const useMutation = <T, V>(
  mutationFn: MutationFunction<T, V>,
  opts?: UseMutationOptions<T, V>
): UseMutationResult<T, V> => {
  const handleApiError = useApiErrorHandler()
  const queryClient = useQueryClient()

  const onError = (err: GeneralError): void => {
    handleApiError(err)
  }

  // eslint-disable-next-line functional/functional-parameters
  const onSuccess: UseMutationOptions<T, V>['onSuccess'] = (...args) => {
    opts?.invalidateQueries?.forEach((k) => void queryClient.invalidateQueries(k))
    void opts?.onSuccess?.(...args)
  }

  const mutate = async (args: V): Promise<T> => {
    // eslint-disable-next-line functional/no-try-statement
    try {
      return await mutationFn(args)
    } catch (err) {
      // eslint-disable-next-line functional/no-throw-statement
      throw toGeneralError(err)
    }
  }

  return useReactMutation<T, GeneralError, V>(mutate, {
    onError,
    ...opts,
    onSuccess
  })
}
