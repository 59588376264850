import * as React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { HtmlMouseEventHandler } from '../../types/html-types'

export type DialogProps = {
  readonly title?: string | React.ReactNode
  readonly children: React.ReactNode
  readonly footer?: React.ReactNode
  readonly className?: string
  readonly onClose?: () => void
}

export type ModalHandle = {
  readonly show: () => void
  readonly hide: () => void
}

const Modal = React.forwardRef<ModalHandle, DialogProps>(({ title, children, footer, className, onClose }, ref) => {
  const [visible, setVisible] = React.useState(false)

  React.useImperativeHandle(ref, () => ({
    show(): void {
      setVisible(true)
    },
    hide(): void {
      setVisible(false)
    }
  }))

  const handleClose: HtmlMouseEventHandler = (e) => {
    e.preventDefault()
    setVisible(false)
    if (onClose) {
      onClose()
    }
  }

  return (
    <Dialog onClose={handleClose} open={visible} className={className} aria-labelledby="modal-title">
      <DialogTitle id="modal-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </Dialog>
  )
})

export default Modal
