import * as React from 'react'
import Modal, { DialogProps, ModalHandle } from '../components/layout/Modal'
import Button, { ButtonProps } from '../components/controls/Button'
import { HtmlMouseEventHandler } from '../types/html-types'
import { MuiThemeProvider, Snackbar } from '@material-ui/core'
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert'
import { snackbarAutoHideDurationInSeconds, themeStorageKey } from '../constants/configuration'
import { generateElementId } from '@digital-magic/ts-common-utils/lib/random'
import { useTranslation } from 'react-i18next'
import { useValidatedLocalStorage } from '../hooks/StorageHooks'
import { CustomTheme, ThemeType } from '../styles/theme/types'
import { theme } from '../styles/theme'
import { ThemeProvider } from 'styled-components'

// TODO: Think about changing message keys to lazy functions that return translated result (to be able to re-render after language switched)

type InfoDialogProps = Omit<DialogProps, 'footer'>
type ConfirmDialogProps = InfoDialogProps & {
  readonly onResponse: (value: boolean) => void
  readonly confirmTitle?: string
  readonly cancelTitle?: string
}

type AlertItem = {
  readonly id: string
  readonly severity: Color
  readonly message: string
}

export type AlertMessage = Omit<AlertItem, 'id'>

export type MuiContextValue = {
  readonly openModal: React.Dispatch<DialogProps>
  readonly openModalInfo: React.Dispatch<InfoDialogProps>
  readonly openModalConfirm: React.Dispatch<ConfirmDialogProps>
  readonly closeModal: React.DispatchWithoutAction
  readonly showAlert: React.Dispatch<AlertMessage>
  readonly currentTheme: CustomTheme
  readonly setCurrentTheme: (type: ThemeType) => void
}

export const MuiContext = React.createContext<MuiContextValue>({} as MuiContextValue)

const Alert: React.FC<AlertProps> = (props) => <MuiAlert elevation={6} variant="filled" {...props} />

type DialogButtonProps = Pick<ButtonProps, 'onClick' | 'color'> & {
  readonly text: string
}

const DialogButton: React.FC<DialogButtonProps> = ({ color, text, onClick }) => (
  <Button variant="contained" color={color} onClick={onClick}>
    {text}
  </Button>
)

type DialogHardcodedTextButtonProps = Pick<ButtonProps, 'onClick'>

const CloseButton: React.FC<DialogHardcodedTextButtonProps> = ({ onClick }) => {
  const { t } = useTranslation()
  return <DialogButton text={t('global.buttons.close')} color="primary" onClick={onClick} />
}

type DialogOptionalTextButtonProps = DialogHardcodedTextButtonProps & {
  readonly text?: string
}

const ConfirmButton: React.FC<DialogOptionalTextButtonProps> = ({ text, onClick }) => {
  const { t } = useTranslation()
  return <DialogButton color="primary" text={text ?? t('global.buttons.yes')} onClick={onClick} />
}

const CancelButton: React.FC<DialogOptionalTextButtonProps> = ({ text, onClick }) => {
  const { t } = useTranslation()
  return <DialogButton color="secondary" text={text ?? t('global.buttons.no')} onClick={onClick} />
}

export const MuiContextProvider: React.FC = ({ children }) => {
  const modalRef = React.useRef({} as ModalHandle)
  const [modalProps, setModalProps] = React.useState<DialogProps | undefined>(undefined)
  const [alerts, setAlerts] = React.useState<ReadonlyArray<AlertItem>>([])
  const [currentThemeType, setCurrentThemeType] = useValidatedLocalStorage(themeStorageKey, 'light', ThemeType)

  const currentTheme = theme(currentThemeType)

  const closeModal: React.DispatchWithoutAction = () => {
    modalRef.current.hide()
    setTimeout(() => setModalProps(undefined), 100)
  }

  const onHideModalClickHandler: HtmlMouseEventHandler = (e) => {
    e.preventDefault()
    closeModal()
  }

  const onHideConfirmClickHandler = (afterHandle: () => void) => (e: React.MouseEvent<HTMLButtonElement>) => {
    onHideModalClickHandler(e)
    afterHandle()
  }

  const openModal: React.Dispatch<DialogProps> = (props) => {
    setModalProps(props)
    modalRef.current.show()
  }

  const openModalInfo: React.Dispatch<InfoDialogProps> = (props) => {
    setModalProps({
      ...props,
      footer: <CloseButton onClick={onHideModalClickHandler} />
    })
    modalRef.current.show()
  }

  const openModalConfirm: React.Dispatch<ConfirmDialogProps> = ({
    confirmTitle,
    cancelTitle,
    onResponse,
    ...props
  }) => {
    setModalProps({
      ...props,
      footer: [
        <ConfirmButton key="confirm" text={confirmTitle} onClick={onHideConfirmClickHandler(() => onResponse(true))} />,
        <CancelButton key="cancel" text={cancelTitle} onClick={onHideConfirmClickHandler(() => onResponse(false))} />
      ]
    })
    modalRef.current.show()
  }

  const showAlert: React.Dispatch<Omit<AlertItem, 'id'>> = (alert) => {
    setAlerts(
      alerts.concat({
        id: generateElementId(),
        ...alert
      })
    )
  }

  const onClose = (id: string) => () => {
    setAlerts(alerts.filter((v) => v.id !== id))
  }

  return (
    <MuiThemeProvider theme={currentTheme}>
      <ThemeProvider theme={currentTheme}>
        <MuiContext.Provider
          value={{
            openModal,
            openModalInfo,
            openModalConfirm,
            closeModal,
            showAlert,
            currentTheme,
            setCurrentTheme: setCurrentThemeType
          }}
        >
          {children}
          <Modal
            ref={modalRef}
            title={modalProps?.title}
            footer={modalProps?.footer}
            className={modalProps?.className}
            onClose={modalProps?.onClose}
          >
            {modalProps?.children ? modalProps.children : ''}
          </Modal>
          {alerts.map((alert, index) => (
            <Snackbar
              key={index}
              open={alerts.length > 0}
              autoHideDuration={snackbarAutoHideDurationInSeconds * 1000}
              onClose={onClose(alert.id)}
            >
              <Alert key={index} severity={alert.severity}>
                {alert.message}
              </Alert>
            </Snackbar>
          ))}
        </MuiContext.Provider>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}
