import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { HttpMethod } from './types'
export { useQuery } from './useQuery'
export { useMutation } from './useMutation'

export const performRequest = async <T>(
  method: HttpMethod,
  url: string,
  config?: Omit<AxiosRequestConfig, 'method' | 'url'>
): Promise<T> => {
  const result = (await axios({
    method,
    url,
    ...config
  })) as AxiosResponse<T>

  return result.data
}
