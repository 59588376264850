export const ASICE = 'application/vnd.etsi.asic-e+zip'
export const JSON = 'application/json'
export const JPEG = 'image/jpeg'
export const PNG = 'image/png'
export const PDF = 'application/pdf'
export const BIN = 'application/octet-stream'
export const XLS = 'application/vnd.ms-excel'
export const XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const DOC = 'application/msword'
export const DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const CSV = 'text/csv'
export const PlainText = 'text/plain'
export const MultipartFormData = 'multipart/form-data'
