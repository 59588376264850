import { apiBaseUrl } from '../../constants/configuration'
import { performRequest, useMutation, useQuery } from '../util'
import { UseMutationResult, UseQueryResult } from '../util/types'
import { LoginProvider, LoginRequest, LoginResponse, SessionId } from './types'

const authUrl = `${apiBaseUrl}/auth`
const authProviderUrl = (loginProvider: LoginProvider): string => `${authUrl}/login/${loginProvider}`

export const getUserKey = 'getUserKey'

export const useLoginUser = (): UseMutationResult<SessionId, LoginRequest> => {
  return useMutation((data: LoginRequest) => performRequest<SessionId>('post', authProviderUrl('native'), { data }))
}

export const useLogoutUser = (): UseMutationResult<void, void> => {
  return useMutation(() => performRequest<void>('post', `${authUrl}/logout`))
}

export const useGetUser = (): UseQueryResult<LoginResponse> => {
  return useQuery({
    queryKey: getUserKey,
    queryFn: () => performRequest<LoginResponse>('get', authUrl),
    enabled: false
  })
}
