import { useQuery as useReactQuery } from 'react-query'
import { GeneralError, toGeneralError } from './errors'
import { useApiErrorHandler } from './useApiErrorHandler'
import { UseQueryOptions, UseQueryResult } from './types'

export const useQuery = <T>(opts: UseQueryOptions<T>): UseQueryResult<T> => {
  const handleApiError = useApiErrorHandler()

  return useReactQuery<T, GeneralError>({
    onError: handleApiError,
    ...opts,
    queryFn: async () => {
      // eslint-disable-next-line functional/no-try-statement
      try {
        if (!opts.queryFn) {
          // eslint-disable-next-line functional/no-throw-statement
          throw new Error('Query function not provided')
        }

        return await opts.queryFn?.()
      } catch (err) {
        // eslint-disable-next-line functional/no-throw-statement
        throw toGeneralError(err)
      }
    }
  })
}
