import * as z from 'zod'
import { Theme, ThemeOptions } from '@material-ui/core'
import { Palette } from '@material-ui/core/styles/createPalette'

export const ThemeType = z.enum(['light'])
export type ThemeType = z.infer<typeof ThemeType>

export type SharedThemeStyles = {
  // styles shared between light and dark theme
}

export type CustomPalette = {
  readonly border: string
} & Palette

export type CustomTheme = Theme & {
  readonly global: SharedThemeStyles
  readonly palette: CustomPalette
  readonly type: ThemeType
}

export type CustomThemeOptions =
  | ThemeOptions
  | {
      readonly palette: CustomPalette
    }

export type CustomThemes = { readonly [key in ThemeType]: CustomThemeOptions }
