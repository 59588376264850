import * as React from 'react'
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom'
import MainLayout from './components/layout/MainLayout'
import Login from './components/pages/Login'
import Main from './components/pages/Main'
import { routes } from './constants/routes'
import { useAuthContext } from './context/AuthContext'

type RouteConfig = {
  readonly routes: ReadonlyArray<RouteProps>
  readonly redirect: string
}

const privateRoutes: ReadonlyArray<RouteProps> = [{ path: routes.Home, component: Main }]

const publicRoutes: ReadonlyArray<RouteProps> = [{ path: routes.Login, component: Login }]

const privateRouterConfig = (): RouteConfig => ({
  routes: privateRoutes,
  redirect: routes.Home
})

const publicRouterConfig = (): RouteConfig => ({
  routes: publicRoutes,
  redirect: routes.Login
})

const App: React.FC = () => {
  const { isLoggedIn } = useAuthContext()

  const { routes, redirect } = React.useMemo(
    () => (isLoggedIn ? privateRouterConfig() : publicRouterConfig()),
    [isLoggedIn]
  )

  return (
    <MainLayout>
      <Switch>
        {routes.map((r) => (
          <Route key={JSON.stringify(r.path)} {...r} />
        ))}
        <Redirect to={redirect} />
      </Switch>
    </MainLayout>
  )
}

export default App
