import * as React from 'react'
import { TextFieldProps as MuiTextFieldProps } from '@material-ui/core'
import { TextInputStyled } from './style'

export type TextFieldProps = MuiTextFieldProps

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(({ helperText = ' ', ...props }, ref) => {
  return <TextInputStyled variant="outlined" size="medium" fullWidth helperText={helperText} {...props} ref={ref} />
})

export default TextField
