export const throttle = (cb: () => void, delay: number): (() => void) => {
  // eslint-disable-next-line functional/no-let
  let timeout: NodeJS.Timeout | null = null

  return () => {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      cb()
      timeout = null
    }, delay)
  }
}

export const tryOrElse = <T>(fn: () => T, defaultValue: () => T): T => {
  // eslint-disable-next-line functional/no-try-statement
  try {
    return fn()
  } catch (e) {
    return defaultValue()
  }
}
