import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../../context/AuthContext'
import Button from '../../controls/Button'
import { Form, FormTextField, useForm } from '../../controls/Form'
import { LoginRequest } from '../../../api/auth/types'
import { LoginStyled } from './style'
import { Card } from '@material-ui/core'

const Login: React.FC = () => {
  const { t } = useTranslation()
  const { login } = useAuthContext()

  const f = useForm({
    resolver: LoginRequest,
    defaultValues: {
      language: 'EN'
    }
  })

  const handleSubmit: React.Dispatch<LoginRequest> = (values) => {
    login(values)
  }

  return (
    <LoginStyled>
      <Card className="inner">
        <h1 className="title">{t('app.title')}</h1>
        <h2 className="subtitle">{t('pages.login.title')}</h2>
        <Form f={f} onSubmit={handleSubmit}>
          <FormTextField name={f.names.email} label={t('pages.login.fields.email')} />
          <FormTextField name={f.names.password} label={t('pages.login.fields.password')} type="password" />
          <Button fullWidth type="submit" variant="contained" size="large" color="primary">
            {t('pages.login.buttons.login')}
          </Button>
        </Form>
      </Card>
    </LoginStyled>
  )
}

export default Login
