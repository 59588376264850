import * as React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { GlobalStyles } from '../../../styles/GlobalStyles'
import { MainLayoutStyled } from './style'

type Props = {
  readonly children: React.ReactNode
}

const MainLayout: React.FC<Props> = ({ children }) => {
  return (
    <MainLayoutStyled>
      <CssBaseline />
      <GlobalStyles />
      <main>{children}</main>
    </MainLayoutStyled>
  )
}

export default MainLayout
