import { TableCell, TableContainer, TableRow, withStyles } from '@material-ui/core'

export const StyledTableContainer = withStyles(() => ({
  root: {
    width: '100%',
    maxHeight: 300
  }
}))(TableContainer)

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily
  },
  body: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily
  }
}))(TableCell)

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow)
