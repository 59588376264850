import styled, { css } from 'styled-components'

export const LoginStyled = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .inner {
      margin-bottom: ${theme.spacing(10)}px;
      max-width: 30rem;
      border-radius: 0.5rem;
      padding: ${theme.spacing(4, 4, 6, 4)};

      form button {
        margin-top: ${theme.spacing(1)}px;
      }
    }
  `}
`
