import { hasValue } from '@digital-magic/ts-common-utils/lib/type'
import axios, { AxiosError } from 'axios'
import { DispatchWithoutAction } from 'react'
import { Dispatch } from 'react'
import { SessionId } from '../api/auth/types'
import { Header } from '../api/util/types'
import * as ContentType from '../constants/content-type'

// eslint-disable-next-line functional/immutable-data
axios.defaults.headers = {
  [Header.Accept]: ContentType.JSON,
  [Header.ContentType]: ContentType.JSON
}

export const isAxiosError = (error: unknown): error is AxiosError => {
  return error instanceof Error && (error as AxiosError).isAxiosError
}

export const setSessionIdToken: Dispatch<SessionId> = (token) => {
  // eslint-disable-next-line functional/immutable-data,@typescript-eslint/no-unsafe-member-access
  axios.defaults.headers[Header.XSessionId] = token
}

export const removeSessionIdToken: DispatchWithoutAction = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (hasValue(axios.defaults.headers[Header.XSessionId])) {
    // eslint-disable-next-line functional/immutable-data,@typescript-eslint/no-unsafe-member-access
    delete axios.defaults.headers[Header.XSessionId]
  }
}
