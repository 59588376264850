import { getWindowConfParam } from '../utils/config-utils'

export const baseUrl = process.env.PUBLIC_URL ?? '/'
export const apiBaseUrl = getWindowConfParam('REACT_APP_API_BASE_URL') ?? 'http://localhost:9080/api/v1'
export const wsBaseUrl = getWindowConfParam('REACT_APP_WSS_BASE_URL') ?? 'ws://localhost:9080/api/v1/ws'

export const appVersion = getWindowConfParam('REACT_APP_VERSION') ?? '0.0.0'

export const dateTimeFormat = 'YYYY-MM-DD HH:mm'
export const dateFormat = 'YYYY-MM-DD'
export const timeFormat = 'HH:mm'

export const snackbarAutoHideDurationInSeconds = 5

export const LanguageConfiguration = {
  translationNamespace: 'translations',
  loadPath: `${baseUrl}/locales/{{lng}}/{{ns}}.json`,
  version: appVersion,
  availableLanguages: ['en', 'et', 'ru'],
  defaultLanguage: 'en',
  storageKey: 'grainstate_cloud_i18nextLng',
  cacheStorageKeyPrefix: 'grainstate_cloud_i18next_res_',
  cacheExpirationMs: process.env.NODE_ENV === 'production' ? 7 * 24 * 60 * 60 * 1000 : 60 * 1000
}

export const themeStorageKey = 'grainstate_cloud_materialTheme'
export const authStateStorageKey = 'grainstate_cloud_authState'

console.log('Application configuration loaded:', {
  baseUrl: baseUrl,
  version: appVersion,
  languages: LanguageConfiguration
})
