import * as React from 'react'
import * as z from 'zod'
import { authStateStorageKey } from '../constants/configuration'
import { hasValue } from '@digital-magic/ts-common-utils/lib/type'
import { LoginRequest, LoginResponse, SessionId } from '../api/auth/types'
import { useLoginUser, useLogoutUser, useGetUser } from '../api/auth'
import { removeSessionIdToken, setSessionIdToken } from '../utils/axios-utils'
import { useHistory } from 'react-router-dom'
import { useValidatedLocalStorage } from '../hooks/StorageHooks'
import { routes } from '../constants/routes'

const StoredSessionData = LoginResponse.extend({
  sessionId: SessionId
}).optional()
type StoredSessionData = z.infer<typeof StoredSessionData>

type AuthContextValue = {
  readonly isLoggedIn: boolean
  readonly sessionData: StoredSessionData
  readonly login: React.Dispatch<LoginRequest>
  readonly logout: React.DispatchWithoutAction
}

const AuthContext = React.createContext({} as AuthContextValue)

export const AuthContextProvider: React.FC = ({ children }) => {
  const [sessionData, setSessionData] = useValidatedLocalStorage(authStateStorageKey, undefined, StoredSessionData)

  const loginUser = useLoginUser()
  const logoutUser = useLogoutUser()
  const getUser = useGetUser()
  const history = useHistory()

  React.useEffect(() => {
    const fetchUserData = async (): Promise<void> => {
      if (loginUser.data) {
        setSessionIdToken(loginUser.data)
        const userData = await getUser.refetch()

        if (userData.data) {
          setSessionData({
            ...userData.data,
            sessionId: loginUser.data
          })
        }
      }
    }

    void fetchUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginUser.data])

  React.useEffect(() => {
    if (sessionData?.sessionId) {
      setSessionIdToken(sessionData.sessionId)
    }
  }, [sessionData?.sessionId])

  const login = React.useCallback(
    (data: LoginRequest) => {
      loginUser.mutate(data)
    },
    [loginUser]
  )

  const logout = React.useCallback(async () => {
    removeSessionIdToken()
    setSessionData(undefined)
    await logoutUser.mutateAsync(undefined)
    history.push(routes.Login)
  }, [logoutUser, setSessionData, history])

  const isLoggedIn = React.useMemo(() => hasValue(sessionData), [sessionData])

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isLoggedIn,
        sessionData
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = (): AuthContextValue => {
  return React.useContext(AuthContext)
}
