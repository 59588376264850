import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'
import { styled as materialStyled } from '@material-ui/core/styles'
import { spacing } from '@material-ui/system'

const ExtendedButton = materialStyled(Button)(spacing)

export const ButtonStyled = styled(ExtendedButton)`
  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    text-transform: none;
  `}
`
