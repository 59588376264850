import * as z from 'zod'
import { CompanyId, UserId } from '../types'

export const Company = z.object({
  id: CompanyId,
  owner: UserId,
  name: z.string().nonempty(),
  registrationNumber: z.string().nonempty(),
  phoneNumber: z.string().nonempty(),
  mail: z.string().nonempty()
})
export type Company = z.infer<typeof Company>

export const UpdateCompanyRequest = z.object({
  name: z.string().nonempty(),
  registrationNumber: z.string().nonempty(),
  phoneNumber: z.string().nonempty(),
  email: z.string().nonempty()
})
export type UpdateCompanyRequest = z.infer<typeof UpdateCompanyRequest>
