import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import App from './App'
import { MuiContextProvider } from './context/MuiContext'
import { AuthContextProvider } from './context/AuthContext'
import i18n from './i18n'
import reportWebVitals from './reportWebVitals'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './api'
import { BrowserRouter } from 'react-router-dom'
import { baseUrl } from './constants/configuration'

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="Loading...">
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter basename={baseUrl}>
            <AuthContextProvider>
              <MuiContextProvider>
                <App />
              </MuiContextProvider>
            </AuthContextProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
