import * as z from 'zod'

export type LoginProvider = 'native'

export const SessionId = z.string().nonempty()
export type SessionId = z.infer<typeof SessionId>

export const LoginRequest = z.object({
  language: z.string().nonempty(),
  email: z.string().email(),
  password: z.string().nonempty()
})
export type LoginRequest = z.infer<typeof LoginRequest>

export const LoginResponse = z.object({
  userId: z.string().nonempty(),
  email: z.string().nonempty(),
  since: z.string().nonempty()
})
export type LoginResponse = z.infer<typeof LoginResponse>
