import * as React from 'react'
import { Table, TableBody, TableHead, TableRow } from '@material-ui/core'
import { StyledTableCell, StyledTableContainer, StyledTableRow } from './style'
import { HasId, TinyTableHeaderColumn, TinyTableOptions } from './types'

function tinyTable<T>(): React.FC<TinyTableOptions<T>> {
  return (opts) => {
    return (
      <StyledTableContainer>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {opts.columns.map((column: TinyTableHeaderColumn<T>) => (
                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                  {column.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {opts.data?.map((row: T & HasId) => (
              <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={opts.createHandler(row)}>
                {opts.columns.map((column: TinyTableHeaderColumn<T>) => {
                  return (
                    <StyledTableCell key={column.id} align={column.align}>
                      {column.format(row)}
                    </StyledTableCell>
                  )
                })}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    )
  }
}

export default tinyTable
