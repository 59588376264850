import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import TextField, { TextFieldProps } from '../TextField'
import { FormInputProps } from './types'
import { useFormInputProps } from './util'
import { hasValue, isNotEmptyString, OptionalString } from '@digital-magic/ts-common-utils/lib/type'

type FormTextInputProps = FormInputProps<string> & Omit<TextFieldProps, 'name' | 'value' | 'onChange'>

const output = (value: OptionalString): OptionalString => (isNotEmptyString(value) ? value : undefined)
const input = (value: OptionalString): string => (hasValue(value) ? value : '')

const FormTextField: React.FC<FormTextInputProps> = (props) => {
  const f = useFormContext()
  const inputProps = useFormInputProps<string>(props)

  return (
    <Controller
      control={f.control}
      name={inputProps.name}
      defaultValue={undefined}
      render={({ field }) => (
        <TextField
          //InputLabelProps={{ shrink: true }}
          {...props}
          {...inputProps}
          {...field}
          inputProps={{ ...props.inputProps }}
          value={input(field.value)}
          onChange={(e) => field.onChange(output(e.target.value))}
        />
      )}
    />
  )
}

export default FormTextField
