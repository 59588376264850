import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MuiContext } from '../../context/MuiContext'
import { ApiErrorCode, ErrorType, GeneralError } from './errors'
import { useAuthContext } from '../../context/AuthContext'

type UseApiErrorHandlerResult = (err: GeneralError) => void

const i18nErrorKey = (key: string): string => `messages.communication-error.${key}`

export const useApiErrorHandler = (): UseApiErrorHandlerResult => {
  const { t, i18n } = useTranslation()
  const { showAlert } = React.useContext(MuiContext)
  const auth = useAuthContext()

  return (err: GeneralError) => {
    // eslint-disable-next-line functional/no-let, prefer-const
    let errKey
    switch (err.type) {
      case ErrorType.enum.ApiError:
        errKey = i18nErrorKey(err.extCode)
        switch (err.extCode) {
          case ApiErrorCode.enum.SessionNotFound:
          case ApiErrorCode.enum.SessionInvalidOrExpired:
            auth.logout()
        }
        break
      case ErrorType.enum.HttpError:
        if (err.code && err.code === '401') {
          auth.logout()
        }
        errKey = [i18nErrorKey(`http-error.${err.code ?? 'default'}`), i18nErrorKey('http-error.default')]
        break
      default:
        errKey = i18nErrorKey('default')
        break
    }

    const message = i18n.exists(errKey) ? t(errKey) : t(i18nErrorKey('default'))
    showAlert({ severity: 'error', message })
  }
}
