import * as React from 'react'
import { MainStyled } from './style'
import AddIcon from '@material-ui/icons/Add'
import { useTranslation } from 'react-i18next'
import { Fab, Grid } from '@material-ui/core'
import {
  useCompaniesQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation
} from '../../../api/companies/compainesApi'
import tinyTable, { TinyTableHeaderColumn } from '../../layout/TinyTable'
import TableToolbar from '../../layout/TableToolbar'
import { Company, UpdateCompanyRequest } from '../../../api/companies/types'
import { HtmlMouseButtonEventHandler } from '../../../types/html-types'
import CompanyForm from './CompanyForm'
import { MuiContext } from '../../../context/MuiContext'

const CompanyTinyTable = tinyTable<Company>()

type CompanyUpdateFormProps = {
  readonly company: Company
  readonly onUpdateSuccess: (data: UpdateCompanyRequest) => void
}

const CompanyUpdateForm: React.FC<CompanyUpdateFormProps> = ({ company, onUpdateSuccess }) => {
  const updateCompany = useUpdateCompanyMutation(company.id)

  const onFormUpdateSuccess = (c: UpdateCompanyRequest): void => {
    updateCompany.mutate(c)
    onUpdateSuccess(c)
  }

  return <CompanyForm onUpdateSuccess={onFormUpdateSuccess} company={company} />
}

const Main: React.FC = () => {
  const { t } = useTranslation()
  const { openModal, closeModal } = React.useContext(MuiContext)

  const fetchCompanies = useCompaniesQuery()
  const createCompany = useCreateCompanyMutation({ onSuccess: closeModal })

  const companiesData = React.useMemo(() => fetchCompanies.data ?? [], [fetchCompanies.data])

  const reloadCompanies = React.useCallback(() => fetchCompanies.refetch(), [fetchCompanies])

  const companyColumns: ReadonlyArray<TinyTableHeaderColumn<Company>> = [
    {
      id: 'name',
      name: t('pages.main.companies.table.name'),
      align: 'left',
      minWidth: 100,
      format: (a) => a.name
    },
    {
      id: 'registrationNumber',
      name: t('pages.main.companies.table.registrationNumber'),
      align: 'left',
      minWidth: 60,
      format: (a) => a.registrationNumber
    },
    {
      id: 'email',
      name: t('pages.main.companies.table.email'),
      align: 'left',
      minWidth: 100,
      format: (a) => a.mail
    },
    {
      id: 'phone',
      name: t('pages.main.companies.table.phone'),
      align: 'left',
      minWidth: 60,
      format: (a) => a.phoneNumber
    }
  ]

  const onCompanyEditClick = (company: Company) => (e: React.MouseEvent) => {
    e.preventDefault()
    console.log('Company clicked', company)
    openModal({
      title: t('dialog.edit-company.title'),
      children: <CompanyUpdateForm company={company} onUpdateSuccess={closeModal} />
    })
  }

  const onCompanyAddClick: HtmlMouseButtonEventHandler = (e) => {
    e.preventDefault()
    openModal({
      title: t('dialog.add-company.title'),
      children: <CompanyForm onUpdateSuccess={createCompany.mutate} />
    })
  }

  return (
    <MainStyled>
      {t('pages.main.title')}
      <Grid container spacing={3}>
        <Grid item xs={12} lg={6}>
          <TableToolbar title={t('pages.main.companies.title')} reload={reloadCompanies} />
          <CompanyTinyTable columns={companyColumns} data={companiesData} createHandler={onCompanyEditClick} />
          <Fab color="primary" aria-label="add" onClick={onCompanyAddClick}>
            <AddIcon />
          </Fab>
        </Grid>
      </Grid>
    </MainStyled>
  )
}

export default Main
