import { createTheme } from '@material-ui/core/styles'
import { CustomTheme, CustomThemes, ThemeType } from './types'
import { lightTheme } from './light'
import { sharedThemeStyles } from './shared'

const themes: CustomThemes = {
  light: lightTheme
}

export const theme = (type: ThemeType): CustomTheme => ({
  ...(createTheme(themes[type]) as CustomTheme),
  global: sharedThemeStyles,
  type
})
