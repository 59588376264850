import {
  QueryKey,
  UseQueryOptions as UseReactQueryOptions,
  UseQueryResult as UseReactQueryResult,
  UseMutationOptions as UseReactMutationOptions,
  UseMutationResult as UseReactMutationResult
} from 'react-query'
import { GeneralError } from './errors'

// TODO: Enums are BAD
export enum Header {
  Accept = 'Accept',
  ContentType = 'Content-Type',
  Authorization = 'authorization',
  XSessionId = 'X-SessionID'
}

export const HttpMethod = ['get', 'post', 'put', 'patch', 'delete'] as const
export type HttpMethod = typeof HttpMethod[number]

export type UseQueryOptions<T> = {
  readonly queryFn?: () => Promise<T>
  readonly queryKey: QueryKey
} & UseReactQueryOptions<T, GeneralError, T, QueryKey>

export type UseMutationOptions<T, V> = UseReactMutationOptions<T, GeneralError, V> & {
  readonly invalidateQueries?: ReadonlyArray<QueryKey>
}

export type UseQueryResult<T> = UseReactQueryResult<T, GeneralError>

export type UseMutationResult<T, V> = UseReactMutationResult<T, GeneralError, V>

export type WithTag = {
  readonly _tag: string
}
