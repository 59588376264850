import { apiBaseUrl } from '../../constants/configuration'
import { performRequest, useMutation, useQuery } from '../util'
import { UseMutationOptions, UseMutationResult, UseQueryResult } from '../util/types'
import { QueryKey } from 'react-query'
import { Company, UpdateCompanyRequest } from './types'
import { CompanyId } from '../types'

const listCompaniesQueryKey: QueryKey = 'all-companies'

const companiesUrl = `${apiBaseUrl}/companies`
const companyUrl = (companyId: CompanyId): string => `${companiesUrl}/${companyId}`

export const useCompaniesQuery = (): UseQueryResult<ReadonlyArray<Company>> =>
  useQuery({
    queryKey: listCompaniesQueryKey,
    queryFn: () => performRequest<ReadonlyArray<Company>>('get', companiesUrl)
  })

export const useCreateCompanyMutation = (
  opts?: UseMutationOptions<void, UpdateCompanyRequest>
): UseMutationResult<void, UpdateCompanyRequest> =>
  useMutation((body) => performRequest<void>('post', companiesUrl, { data: body }), {
    invalidateQueries: [listCompaniesQueryKey],
    ...opts
  })

export const useUpdateCompanyMutation = (
  companyId: CompanyId,
  opts?: UseMutationOptions<void, UpdateCompanyRequest>
): UseMutationResult<void, UpdateCompanyRequest> =>
  useMutation((body) => performRequest('post', companyUrl(companyId), { data: body }), {
    invalidateQueries: [listCompaniesQueryKey],
    ...opts
  })
