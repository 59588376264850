import { get, useFormContext } from 'react-hook-form'
import { getPath } from 'ts-object-path'
import * as z from 'zod'
import { FormInputProps } from './types'
import { arrayPathToString } from '../../../utils/object-utils'
import { hasValue, OptionalString } from '@digital-magic/ts-common-utils/lib/type'
import { zodIs } from '../../../utils/zod-utils'
import { TFunction } from 'react-i18next'
import { defaultErrorMap, ZodErrorMap } from 'zod'
import { ZodIssueOptionalMessage } from 'zod/lib/ZodError'

type UseFormInputPropsResult = {
  readonly name: string
  readonly error?: boolean
  readonly helperText?: string
}

const FieldError = z.object({
  message: z.string()
})

export const useFormErrorMessage = (name: string): string | undefined => {
  const f = useFormContext()

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const err: unknown = get(f.formState.errors, name)

  return zodIs(err, FieldError) ? err.message : undefined
}

export const useFormInputProps = <T>(props: FormInputProps<T>): UseFormInputPropsResult => {
  const name = arrayPathToString(getPath(props.name))
  const error = useFormErrorMessage(name)

  return {
    name,
    error: hasValue(error),
    helperText: error
  }
}

const buildErrorMessage = (t: TFunction, error: ZodIssueOptionalMessage): OptionalString => {
  // if more error translations are needed, add cases for them here
  // example: https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md#customizing-errors-with-zoderrormap
  switch (error.code) {
    case z.ZodIssueCode.invalid_string:
      if (error.validation === 'email') {
        return t('global.form_errors.wrong_email')
      }
      return undefined
    case z.ZodIssueCode.invalid_type:
      if (error.received === 'undefined') {
        return t('global.form_errors.required')
      } else {
        return t('global.form_errors.invalid_type')
      }
    case z.ZodIssueCode.too_small:
      if (error.type === 'string') {
        if (error.minimum === 1) {
          return t('global.form_errors.required')
        } else {
          return t('global.form_errors.min_strlen', { minLength: error.minimum })
        }
      }
      return undefined
    case z.ZodIssueCode.too_big:
      if (error.type === 'string') {
        return t('global.form_errors.max_strlen', { maxLength: error.maximum })
      }
      return undefined
    /*
    case z.ZodIssueCode.custom:
      if (error.path.includes(NewPasswordPath)) {
        return t('global.form_errors.custom.password_strength')
      }
      return undefined
    */
    default:
      return undefined
  }
}

export const errorMap =
  (t: TFunction): ZodErrorMap =>
  (error, ctx) => {
    const message = buildErrorMessage(t, error)
    return message ? { message } : defaultErrorMap(error, ctx)
  }
